<template>
  <div class="container">
    <el-card>
      <div class="pro-date mb-1">
        <span>
          {{ $t("plans.currentPlan") }}:
          <strong v-if="isPro" class="text-success ml-2 current-plan"
            ><i class="fas fa-crown"></i> Pro</strong
          >
          <strong v-else class="text-success ml-2 current-plan">Free</strong>
        </span>
        <router-link v-if="!isPro" :to="{ name: 'ToeflPlans' }">
          <el-button type="primary" size="small">
            {{ $t("plans.upgrade") }}
          </el-button>
        </router-link>
      </div>
      <div class="pro-date mb-3" v-if="isPro">
        <span class="text-info plan-duration">{{
          `${$t("plans.planDuration")} ${proTimeLimited} (GMT+8)`
        }}</span>
      </div>
      <template v-if="isPro">
        <div
          v-for="(subscription, index) in subscriptions.filter(subscription => {
            return subscription.status === 'active';
          })"
          :key="index"
        >
          <hr />
          <div class="my-subscription">
            <div class="mb-2">
              <div class="pro-date">
                <span>
                  {{ $t("Pro.Next billing date") }}
                  <b
                    class="ml-2 text-info"
                    v-if="subscription.cancel_at_period_end === 0"
                  >
                    {{
                      instant.formatGoogleTime(subscription.current_period_end)
                    }}
                  </b>
                  <b
                    class="ml-2"
                    style="text-decoration:line-through;opacity: 0.5;"
                    v-else-if="subscription.cancel_at_period_end === 1"
                  >
                    {{
                      instant.formatGoogleTime(subscription.current_period_end)
                    }}
                  </b>
                </span>
              </div>
              <div class="pro-date">
                <span>
                  Pro:
                  <span class="ml-2">
                    <b
                      v-if="
                        subscription.package.recurring.interval === 'month' &&
                          subscription.package.recurring.interval_count === 1
                      "
                    >
                      {{ $t("Pro.1 month") }}
                    </b>
                    <b
                      v-if="
                        subscription.package.recurring.interval === 'month' &&
                          subscription.package.recurring.interval_count === 3
                      "
                    >
                      {{ $t("Pro.3 months") }}
                    </b>
                    <b
                      v-if="
                        subscription.package.recurring.interval === 'month' &&
                          subscription.package.recurring.interval_count === 6
                      "
                    >
                      {{ $t("Pro.6 months") }}
                    </b>
                  </span>
                </span>
              </div>
              <div class="pro-date">
                <span>
                  {{ $t("Pro.Payment amount") }}:
                  <b class="text-info ml-2">
                    ${{ parseInt(subscription.package.original_price) }}
                    {{ subscription.package.price_currency }}
                  </b>
                </span>
              </div>
            </div>
            <div>
              <el-button
                type="danger"
                size="mini"
                plain
                @click="unsubscribe(subscription.id)"
                v-if="subscription.cancel_at_period_end === 0"
              >
                {{ $t("Pro.Cancel subscription") }}
              </el-button>
              <el-button
                v-else-if="subscription.cancel_at_period_end === 1"
                type="primary"
                size="mini"
                @click="reactivating(subscription.id)"
              >
                {{ $t("Pro.Auto-Renewable") }}
              </el-button>
            </div>
          </div>
        </div>
        <!-- </template> -->
        <!-- <template v-else>
          <div class="pro-date">
            <span>
              {{ $t("Pro.Next billing date") }}
              <b class="ml-2"> - </b>
            </span>
            <router-link :to="{ name: 'ToeflPlans' }">
              <el-button
                type="primary"
                size="small"
                @click="unsubscribe(subscription.id)"
              >
                {{ $t("Pro.Auto-Renewable") }}
              </el-button>
            </router-link>
          </div>
        </template> -->
      </template>
      <template
        v-if="
          subscriptions.filter(subscription => {
            return subscription.status !== 'active';
          }).length > 0
        "
      >
        <hr />
        <h6 class="text-left">
          {{ $t("Pro.History Subscription") }}
        </h6>
        <div
          class="text-left"
          v-for="(subscription, index) in subscriptions.filter(subscription => {
            return subscription.status !== 'active';
          })"
          :key="index"
        >
          <span v-if="isPro">
            <template
              v-if="subscription.package.recurring.interval === 'month'"
            >
              <span v-if="subscription.package.recurring.interval_count === 1">
                Pro {{ $t("Pro.1 month") }}:
              </span>
              <span v-if="subscription.package.recurring.interval_count === 3">
                Pro {{ $t("Pro.3 months") }}:
              </span>
              <span v-if="subscription.package.recurring.interval_count === 6">
                Pro {{ $t("Pro.6 months") }}:
              </span>
            </template>
          </span>
          <span class="">
            {{ instant.formatGoogleTime(subscription.current_period_start) }}
            ~
            {{ instant.formatGoogleTime(subscription.current_period_end) }}
          </span>
        </div>
      </template>
      <div class="is-Pro">
        <hr />
        <div class="row text-left">
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.accessOnAnyDevice") }}
            <span class="mr-1"><i class="fas fa-mobile-alt"/></span>
            <span class="mr-1"><i class="fas fa-tablet-alt"/></span>
            <span class="mr-1"><i class="fas fa-desktop"/></span>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.practiceAnytime") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.ivyWayToefl") }}
          </div>
          <div class="col-sm-6" v-show="!isPro">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.twoFullTestsAndFivePractices") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.unlimitedDailyPractices") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.customVocabLists") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.adFree") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.freeToeflMaterials") }}
            <el-tooltip
              :content="$t('plans.downloadAllMaterials')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.freeTestTakersScoreReport") }}
            <router-link :to="{ name: 'ToeflScorereport' }">
              <i class="fas fa-question-circle" />
            </router-link>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.priorityGrading") }}
            <el-tooltip
              :content="$t('plans.priorityGradingTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.discountForGradingServices") }}
            <el-tooltip
              v-if="langIsEn"
              :content="$t('plans.discountForGradingServicesTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Plans from "@/apis/plans";
import { instant, user } from "@ivy-way/material";
export default {
  components: {},
  mixins: [],
  props: ["isPro", "proTimeLimited"],
  data() {
    return {
      subscriptions: []
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    langIsEn() {
      return this.$store.getters["user/langShortValue"] === "en";
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  },
  async created() {
    this.getSubscriptions();
  },
  mounted() {},
  methods: {
    async getSubscriptions() {
      this.subscriptions = null;
      const res = await Plans.getSubscriptions();
      this.subscriptions = res.subscriptions.data;
    },
    async reactivating(id) {
      const res = await Plans.reactivating(id);
      setTimeout(async () => {
        await this.getSubscriptions();
      }, 2000);
    },
    unsubscribe(id) {
      this.$confirm(
        this.$t("Pro.You ll be canceling your subscription"),
        this.$t("Pro.Warning"),
        {
          confirmButtonText: this.$t("Pro.Yes"),
          cancelButtonText: this.$t("Pro.No"),
          type: "warning"
        }
      ).then(async () => {
        await Plans.unsubscribe(id);
        setTimeout(async () => {
          await this.getSubscriptions();
        }, 1000);
      });
    },
    async getPro(interval, intervalCount) {
      const loading = this.$loading({
        lock: true,
        text: "正在跳轉支付，請稍後。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await Plans.subscribePro({
        interval: interval,
        interval_count: intervalCount,
        success_url: "https://testadmit.com/plans/setting",
        cancel_url: "https://testadmit.com/plans/setting"
      });
      window.location.href = res.url;
      // loading.close();
    }
  }
};
</script>

<style scoped>
.current-plan {
  font-size: 1.5rem;
}
.timedLimited {
  color: #00000077;
}
.is-Pro .col-sm-6 {
  margin-bottom: 0.5rem;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
.container {
  max-width: 800px;
  padding: 20px 0;
}
::v-deep .el-card__body {
  /* padding: 0;
  padding-left: 20px;
  padding-right: 10px; */
}
.pro-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
}
.my-subscription {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.pro-date span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-info {
  /* width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center; */
  font-size: 14px;
  color: #409eff !important;
}
.text-info.plan-duration {
  color: #aaa !important;
}
.pro-status {
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
}

@media screen and (max-width: 1220px) {
  .my-subscription {
    display: block;
    text-align: left;
    /* justify-content: space-between; */
    /* align-items: center; */
  }
}
.col-sm-6 > .mr-1 {
  display: inline-block;
  width: 1rem;
}
.text-danger {
  color: red !important;
}
</style>
<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 95%;
  }
}
</style>
